import React from "react";
import { TagContext } from "context/TagContext";

export const useTagContext = () => {
  const tagContext = React.useContext(TagContext);

  if (!tagContext) {
    throw new Error("useTagContext must be used within a TagProvider");
  }

  return tagContext;
};
