import React from "react";
import { DisplayContext } from "context/DisplayContext";

const useDisplayContext = () => {
  const context = React.useContext(DisplayContext);

  if (!context) {
    throw new Error("useDisplayContext must be used within a DisplayProvider");
  }

  return context;
};

export default useDisplayContext;
