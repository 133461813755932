import { TTag } from "types/api";

interface CacheTagDataArgs {
  tag: TTag;
  cmac: string;
  ctr: string;
}

export interface CachedTagData extends CacheTagDataArgs {
  timestamp: number;
}

// Time in milliseconds to hold cached tag data.
const CACHE_TIME = 30 * 60 * 1000;

const getCacheKey = (tagId: string) => `tag-cache-${tagId}`;

export const cacheTagData = ({ tag, cmac, ctr }: CacheTagDataArgs) => {
  const tagData: CachedTagData = {
    tag,
    cmac,
    ctr,
    timestamp: Date.now(),
  };

  window.localStorage.setItem(getCacheKey(tag.uuid), JSON.stringify(tagData));

  return tagData;
};

export const clearTagData = (tagId: string) => {
  window.localStorage.removeItem(getCacheKey(tagId));
};

interface GetTagDataArgs {
  tagId: string;
  cmac: string;
  ctr: string;
}

export const getTagData = ({ tagId, cmac, ctr }: GetTagDataArgs) => {
  const rawTagData = window.localStorage.getItem(getCacheKey(tagId));

  if (!rawTagData) {
    return undefined;
  }

  const parsedTagData = JSON.parse(rawTagData) as CachedTagData;

  if (
    Date.now() - parsedTagData.timestamp >= CACHE_TIME ||
    cmac !== parsedTagData.cmac ||
    ctr !== parsedTagData.ctr
  ) {
    clearTagData(tagId);
    return undefined;
  }

  return parsedTagData;
};
