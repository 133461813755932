export const LGT_IPFS_GATEWAY_URL = "ipfs.legitimate.tech/ipfs/";

export const SIMPLE_HASH_API_KEY =
  "legitimate_sk_cb24a3ee-b1ae-4b9d-acad-28433473d4f1_b8aw1es44uso7uyz";
export const SIMPLE_HASH_API_URL = "https://api.simplehash.com";

export const WALLET_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
