import React from "react";
import UAParser from "ua-parser-js";
import mobile from "is-mobile";

const useMobile = () => {
  const [isMobile, setIsMobile] = React.useState(mobile());

  const parser = new UAParser();
  const device = parser.getDevice();

  React.useEffect(() => {
    const resize = () => {
      if (device.type === "mobile" || window.innerWidth < 768) {
        setIsMobile(true);
      }
    };

    addEventListener("resize", resize);
    resize();

    return () => {
      removeEventListener("resize", resize);
    };
  }, [device.type]);

  return isMobile;
};

export default useMobile;
