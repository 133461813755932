import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

if (import.meta.env.VITE_API_STAGE === "development") {
  const searchParams = new URLSearchParams(window.location.search);
  const shouldRunBugsnag = searchParams.get("bugsnag") === "1";

  if (shouldRunBugsnag) {
    Bugsnag.start({
      apiKey: "80016d6101589816b37913fef7abf34f",
      plugins: [new BugsnagPluginReact()],
      releaseStage: "staging",
    });

    Bugsnag.notify(new Error("Test error"));
  }
} else if (import.meta.env.VITE_API_STAGE === "production") {
  Bugsnag.start({
    apiKey: "80016d6101589816b37913fef7abf34f",
    plugins: [new BugsnagPluginReact()],
    releaseStage: "production",
  });
}
