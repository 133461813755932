import React from "react";

import { builder, BuilderComponent } from "@builder.io/react";

const BUILDER_IO_PUBLIC_API_KEY = "a6378b4031b246ebad5efa7540a24082";

interface BuilderContentProps {
  builderPath?: string;
  builderAPIKey?: string;
}

const BuilderContent: React.FC<BuilderContentProps> = ({
  builderPath,
  builderAPIKey,
}) => {
  const [pageJson, setPage] = React.useState();

  React.useEffect(() => {
    builder.init(builderAPIKey || BUILDER_IO_PUBLIC_API_KEY);
    builder
      .get("page", { url: builderPath })
      // The value will be `null` if no page was found
      .promise()
      .then(setPage);
  }, [builderAPIKey, builderPath]);

  return pageJson ? <BuilderComponent model="page" content={pageJson} /> : null;
};

export default BuilderContent;
