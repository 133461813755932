import { Dialog, Fade } from "@mui/material"
import Loading from "./Loading"
import useLoadingDelay from "hooks/useLoadingDelay";

const LoadingOverlay = ({ open }: { open: boolean }) => {
  const isLogoAnimating = useLoadingDelay();

  return <Dialog open={isLogoAnimating || open}
    fullScreen
    TransitionComponent={Fade}
    transitionDuration={{
      exit: 200,
    }}
    TransitionProps={{
      easing: "ease-in" // slow at the beginning, fast at the end
    }}
    disableEscapeKeyDown
    PaperProps={{
      sx: { backgroundColor: "black" },
      elevation: 0,
    }}
  >
    <Loading />
  </Dialog>
}

export default LoadingOverlay
