import { Box } from "@mui/material";
import Symbol from "assets/images/symbol.svg?react";

interface HeaderProps {
  right?: React.ReactNode;
  logoUrl?: string;
}

const Header: React.FC<HeaderProps> = ({ right, logoUrl }) => {
  return (
    <Box
      sx={{
        color: "#FFFFFF",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 1,
        mb: 3,
      }}
    >
      {logoUrl && (
        <img
          src={logoUrl}
          alt="logo"
          style={{ height: "36px", maxWidth: "60vw" }}
        />
      )}
      {!logoUrl && <Symbol height={36} style={{ color: "#FFFFFF" }} />}
      {right}
    </Box>
  );
};

export default Header;
