import { TClaimNFT, TTag } from "types/api";
import { NETWORK } from "./chains";

export interface NFTMetadata {
  animation_url?: string;
  image?: string;
  name?: string;
  description?: string;
}

export const parseTokenURI = async (tokenURI: string) => {
  let metadata: NFTMetadata;
  if (tokenURI.indexOf("data:application/json;base64,") > -1) {
    // base64 encoded json doesn't include the proper unicode formatting for international language support and expanded char sets
    // this is a workaround from https://andre.arko.net/2012/09/18/force-encoding-in-javascript/
    const decodedStr = decodeURIComponent(
      Array.prototype.map
        .call(
          window.atob(tokenURI.replace("data:application/json;base64,", "")),
          (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2),
        )
        .join(""),
    );

    metadata = JSON.parse(decodedStr);
  } else {
    const httpUrl = tokenURI.replace(
      "ipfs://",
      "https://cloudflare-ipfs.com/ipfs/",
    );
    const response = await fetch(httpUrl);
    const data = await response.json();
    metadata = data;
  }

  return metadata;
};

export const getDefaultMetadata = (tag: TTag) => {
  const rawData = tag.metadata?.data.find(
    ({ type }) => type === "claim_nft" || type === "magic_claim_nft",
  ) as TClaimNFT;

  if (!rawData) {
    return undefined;
  }

  return {
    name: rawData.options.prerevealTitle!,
    description: rawData.options.prerevealDescription!,
    image: rawData.options.prerevealImageUrl!,
  } as NFTMetadata;
};

export const OPENSEA_BASE_URL_FOR_CHAIN_ID = {
  [NETWORK.ETHEREUM]: "opensea.io/assets",
  [NETWORK.ETHEREUM_SEPOLIA]: "testnets.opensea.io/assets/sepolia",
  [NETWORK.POLYGON]: "opensea.io/assets/matic",
  [NETWORK.POLYGON_AMOY]: "testnets.opensea.io/assets/amoy",
  [NETWORK.AVALANCHE]: "opensea.io/assets/avalanche",
  [NETWORK.AVALANCHE_FUJI]: "testnets.opensea.io/assets/avalanche-fuji",
  [NETWORK.ARBITRUM_ONE]: "opensea.io/assets/arbitrum",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "testnets.opensea.io/assets/arbitrum-sepolia",
  [NETWORK.ARBITRUM_NOVA]: "testnets.opensea.io/assets/arbitrum-nova",
  [NETWORK.LAMINA1]: "",
  [NETWORK.LAMINA1_FUJI]: "",
  [NETWORK.BASE]: "opensea.io/assets/base",
  [NETWORK.BASE_SEPOLIA]: "testnets.opensea.io/assets/base-sepolia",
};

export const generateOpenseaLink = (
  chainId: NETWORK,
  contractAddress: string,
  tokenId: string,
) => {
  return `https://${OPENSEA_BASE_URL_FOR_CHAIN_ID[chainId]}/${contractAddress}/${tokenId}`;
};
