export const getLgtIpfsGatewayURL = (imageUrl: string) => {
  return imageUrl.replace("ipfs.io", "ipfs.legitimate.tech");
};

export const getCloudflareIpfsGatewayURL = (imageUrl: string) => {
  return imageUrl.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");
};

const getPinataImgByWidth = (src: string, width: number) => {
  return `${getLgtIpfsGatewayURL(src)}?img-width=${width}&img-format=auto`;
};

const srcSetWidths = [200, 400, 800, 1200, 1600, 2000, 3000, 4000];

export const getSourceSet = (src: string) => {
  return srcSetWidths
    .map((width) => `${getPinataImgByWidth(src, width)} ${width}w`)
    .join(", ");
};
