import { Box, Typography } from "@mui/material";
import AnimatedLoader from "assets/animations/logo.svg?react";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        color: "#FFFFFF",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AnimatedLoader height={50} width={50} />
      <Typography fontWeight="bold" mt={2}>
        {t("loading-screen.loading-text", "Verifying…")}
      </Typography>
    </Box>
  );
};

export default Loading;
