import i18next from "i18next";
import Locize from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const options = {
  projectId: "ddc68bc7-a2b9-41b1-af7c-46b2b968e3ba",
  referenceLanguage: "en-US",
  fallbackLng: "en-US",
};

i18next
  .use(Locize)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en-US", "ja-JP", "en", "ja"],
    backend: options,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: import.meta.env.DEV,
    fallbackLng: "en-US",
  });

export default i18next;
