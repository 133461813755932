import { API_URL } from "config";
import { get } from "utils/api";
import { CHAIN_ID_TO_SIMPLE_HASH_NETWORK } from "utils/chains";
import { SIMPLE_HASH_API_KEY, SIMPLE_HASH_API_URL } from "utils/constants";

export const fetchTagVerification = (
  tagId: string,
  searchParams: URLSearchParams,
) => {
  return get(
    `${API_URL}/tags/${tagId}/verification?${searchParams.toString()}`,
  );
};

export const fetchTagPublic = (tagId: string) => {
  return get(`${API_URL}/tags/${tagId}/public`);
};

export const fetchTagVerifications = (tagId: string) => {
  return get(`${API_URL}/external/v1/verifications/${tagId}`);
};

interface FetchTagHistoryArgs {
  chainId: number;
  contractAddress: string;
  tokenId: number;
}

export const fetchTagHistory = ({
  chainId,
  contractAddress,
  tokenId,
}: FetchTagHistoryArgs) => {
  const network =
    CHAIN_ID_TO_SIMPLE_HASH_NETWORK[
      chainId as keyof typeof CHAIN_ID_TO_SIMPLE_HASH_NETWORK
    ];

  return get(
    `${SIMPLE_HASH_API_URL}/api/v0/nfts/transfers/${network}/${contractAddress}/${tokenId}`,
    { "X-API-KEY": SIMPLE_HASH_API_KEY },
  );
};
