import React from "react";
import { TMetadata, TTag } from "types/api";
import { NFTMetadata } from "utils/nft";

interface TagContextValue {
  tag: TTag;
  metadata: TMetadata;
  nftMetadata: NFTMetadata;
}

export const TagContext = React.createContext<TagContextValue | null>(null);
