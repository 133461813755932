import { Box, Typography } from "@mui/material";
import Wordmark from "assets/images/wordmark.svg?react";
// import ConsentBanner from "./ConsentBanner";

interface FooterProps {
  padded?: boolean;
}

const Footer: React.FC<FooterProps> = ({ padded }) => {
  return (
    <Box style={{ color: "#FFFFFF" }} pt={2} pb={padded ? 20.5 : 4}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2">Powered by</Typography>
        <a
          href="https://legitimate.tech"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FFFFFF" }}
        >
          <Wordmark height={14} style={{ marginLeft: 8 }} />
        </a>
      </Box>
      {/* <ConsentBanner /> */}
    </Box>
  );
};

export default Footer;
