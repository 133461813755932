import builder, { Builder } from "@builder.io/react";
import { BUILDER_IO_PUBLIC_API_KEY } from "config";

import { Divider, Button } from "@mui/material";

const initializeBuilder = () => {
  builder.init(BUILDER_IO_PUBLIC_API_KEY);

  Builder.registerComponent(Button, {
    name: "Button",
    override: true,
    inputs: [
      {
        name: "children",
        friendlyName: "Text",
        type: "string",
      },
      {
        name: "href",
        friendlyName: "Link",
        type: "string",
      },
      {
        name: "variant",
        type: "string",
        enum: ["contained", "outlined", "text"],
        defaultValue: "text",
      },
      {
        name: "disabled",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "color",
        type: "string",
        enum: ["primary", "secondary", "success", "error", "info", "warning"],
        defaultValue: "primary",
      },
      {
        name: "size",
        type: "string",
        enum: ["small", "medium", "large"],
        defaultValue: "small",
      },
      {
        name: "fullWidth",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "target",
        type: "string",
        defaultValue: "_blank",
      },
    ],
  });

  Builder.registerComponent(Divider, {
    name: "Divider",
    inputs: [
      {
        name: "children",
        type: "string",
      },
      {
        name: "textAlign",
        type: "string",
        enum: ["left", "right"],
      },
    ],
  });
};

initializeBuilder();
