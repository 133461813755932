export interface TTag {
  updated_at: number;
  created_at: number;
  uuid: string;
  template_id: number;
  key: string;
  shareable: boolean;
  name?: string;
  metadata?: TMetadata;
  nft_contract_address?: string;
  nft_chain_id?: number;
  nft_token_id?: number;
  contract_version?: PrimaryContractVersion;
  secondary_nft_contract_address?: string;
  secondary_contract_version?: SecondaryContractVersion;
  counter?: number | null;
  // 0: off, 1: social, 2: social + web3
  web3_authentication_enabled: 0 | 1 | 2;
  redirect_url?: string;
  header_logo_url?: string;
  builder_io_api_key?: string;
  builder_io_url?: string;
}

interface TBuilderIOOptions {
  url: string;
  apiKey?: string;
}

export interface TBuilderIO {
  type: "builder_io";
  version: string;
  options: TBuilderIOOptions;
}

export interface TNFT {
  type: "nft";
  version: string;
}

export interface TUnlockNFT {
  type: "unlock_nft";
  version: "2.0.0";
}

export interface TClaimNFT {
  type: "claim_nft";
  version: "2.0.0";
  options: TClaimNFTOptions;
}

export interface TClaimNFTOptions {
  expirationTime?: string;
  prerevealTitle?: string;
  prerevealDescription?: string;
  prerevealImageUrl?: string;
}

export interface TMagicLinkClaimNFT {
  type: "magic_claim_nft";
  version: "2.0.0";
  options: TMagicLinkClaimNFTOptions;
}

export interface TMagicLinkClaimNFTOptions {
  expirationTime?: string;
  prerevealTitle?: string;
  prerevealDescription?: string;
  prerevealImageUrl?: string;
  emailSubscribeCheckboxLabel?: string;
  mailChimpSubscribeUrl?: string;
  magicPublishableApiKey?: string;
  redirectUrl?: string;
}

export type TMintChildNFTOptions = {
  previewImageUrl?: string;
  blockTitle?: string;
  blockDescription?: string;
};

export interface TMintChildNFT {
  type: "mint_child_nft";
  version: string;
  options: TMintChildNFTOptions;
}

export type TMetadataComponent =
  | TBuilderIO
  | TNFT
  | TUnlockNFT
  | TClaimNFT
  | TMagicLinkClaimNFT
  | TMintChildNFT;

export type TMetadataOptions = Record<string, string> & {
  theme: string;
};

export interface TMetadata {
  type: "lgt_content";
  data: TMetadataComponent[];
  options?: TMetadataOptions;
  version: "2.0.0" | "1.0.0";
}

export enum LGTContractVersion {
  LEGACY_UNSUPPORTED = 0,
  V3_BASE_URI = 1,
  V3_PSI_BASE_URI = 2,
  V3_PSI_ON_CHAIN_METADATA = 3,
  ERC721_NFT_DISTRIBUTION = 4,
  ERC1155_NFT_DISTRIBUTION = 5,
}

export type PrimaryContractVersion =
  | LGTContractVersion.V3_BASE_URI
  | LGTContractVersion.V3_PSI_BASE_URI
  | LGTContractVersion.V3_PSI_ON_CHAIN_METADATA
  | LGTContractVersion.LEGACY_UNSUPPORTED;
export type SecondaryContractVersion =
  | LGTContractVersion.ERC1155_NFT_DISTRIBUTION
  | LGTContractVersion.ERC721_NFT_DISTRIBUTION;
