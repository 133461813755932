import { TFunction } from "i18next";
import { ERROR_TYPES } from "types/errors";

interface ServerError {
  errors: {
    [key: string]: string[];
  };
}

export const parseErrors = (err: ServerError) => {
  let res = "";

  Object.keys(err.errors).forEach((key) => {
    res += `${key} ${err.errors[key]}. `;
  });

  return res;
};

export class NetworkRequestError extends Error {
  error: ServerError;
  status?: number;

  constructor(error: ServerError, status?: number) {
    const message = parseErrors(error);
    super(message);
    this.error = error;
    this.status = status;
  }
}

export const errorTypeToMeaning = (errorType: ERROR_TYPES, t: TFunction) => {
  switch (errorType) {
    case ERROR_TYPES.CMAC_ALREADY_USED:
      return t("error.page.meta.error-type.link-used", "Link Already Used");
    case ERROR_TYPES.CMAC_INVALID:
      return t("error.page.meta.error-type.link-invalid", "Link Invalid");
    case ERROR_TYPES.NOT_SHAREABLE:
      return t(
        "error.page.meta.error-type.tag-not-shareable",
        "Tag Not Shareable",
      );
    case ERROR_TYPES.INACTIVE_TAG:
      return t("error.page.meta.error-type.inactive-tag", "Inactive Tag");
    case ERROR_TYPES.TAG_NOT_DETECTED:
      return t(
        "error.page.meta.error-type.tag-not-detected",
        "Tag Not Detected",
      );
  }
};
