import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { useQuery } from "@tanstack/react-query";
import useTagParams from "hooks/useTagParams";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TMagicLinkClaimNFT, TTag } from "types/api";
import { NetworkRequestError } from "utils/error";
import {
  fetchTagHistory,
  fetchTagPublic,
  fetchTagVerification,
  fetchTagVerifications,
} from "./tags";
import { getNFTMetadata } from "./nft";
import { useTagContext } from "hooks/useTagContext";
import useDisplayContext from "hooks/useDisplayContext";
import { ERROR_TYPES } from "types/errors";
import { cacheTagData, getTagData } from "utils/cache";
import { TagVerificationType } from "utils/tables";

export const useTagVerificationQuery = (searchParams: URLSearchParams) => {
  const { dispatch } = useDisplayContext();
  const { uuid: tagId, cmac, ctr } = useTagParams();
  const navigate = useNavigate();

  return useQuery({
    queryKey: ["tags", tagId, "verification", cmac, ctr],
    queryFn: async () => {
      try {
        if (!tagId || !cmac || !ctr) {
          searchParams.append("err", ERROR_TYPES.TAG_NOT_DETECTED);
          navigate(`/error?${searchParams.toString()}`);
          return;
        }

        let tagData = getTagData({ tagId: tagId!, cmac: cmac!, ctr: ctr! });

        if (!tagData) {
          const response = await fetchTagVerification(tagId!, searchParams);

          if (!response.ok) {
            throw new NetworkRequestError(
              await response.json(),
              response.status,
            );
          }

          const tag: TTag = await response.json();

          const magicClaimNftContent = tag.metadata?.data.find(
            (m) => m.type === "magic_claim_nft",
          ) as TMagicLinkClaimNFT | undefined;

          const redirectUrl =
            magicClaimNftContent?.options.redirectUrl || tag.redirect_url;

          if (redirectUrl) {
            window.location.replace(
              redirectUrl + "?" + searchParams.toString(),
            );
            return;
          }

          if (
            !tag.nft_chain_id ||
            !tag.nft_contract_address ||
            !tag.nft_token_id
          ) {
            searchParams.append("err", ERROR_TYPES.INACTIVE_TAG);
            navigate(`/error?${searchParams.toString()}`);
          }

          tagData = cacheTagData({ tag, cmac: cmac!, ctr: ctr! });
        }

        // We're not caching this because claimed and locked should be live.
        const {
          metadata: nftMetadata,
          claimed,
          locked,
          owner,
        } = await getNFTMetadata(tagData.tag);

        if (!nftMetadata?.image && !nftMetadata?.animation_url) {
          searchParams.append("err", ERROR_TYPES.INACTIVE_TAG);
          navigate(`/error?${searchParams.toString()}`);
        }

        dispatch({
          // If tag has no authentication required, show claimed state.
          claimed: tagData.tag.web3_authentication_enabled === 0 || claimed,
          locked,
          owner,
        });

        return { tag: tagData.tag, nftMetadata };
      } catch (e) {
        if (e instanceof NetworkRequestError) {
          if (e.error.errors.cmac.indexOf("has expired") !== -1) {
            searchParams.append("err", ERROR_TYPES.CMAC_ALREADY_USED);
            navigate(`/error?${searchParams.toString()}`);
          } else if (e.error.errors.cmac.indexOf("is invalid") !== -1) {
            searchParams.append("err", ERROR_TYPES.CMAC_INVALID);
            navigate(`/error?${searchParams.toString()}`);
          } else {
            searchParams.append("err", ERROR_TYPES.INACTIVE_TAG);
            navigate(`/error?${searchParams.toString()}`);
          }
        } else {
          // Only report errors that are not caught above.
          // Do this early because navigation may terminate execution prematurely.
          Bugsnag.notify(e as NotifiableError);

          searchParams.append("err", ERROR_TYPES.INACTIVE_TAG);
          navigate(`/error?${searchParams.toString()}`);
        }
      }
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};

export const useTagPublicQuery = () => {
  const [searchParams] = useSearchParams();
  const { dispatch } = useDisplayContext();

  const tagId = searchParams.get("uid");

  return useQuery({
    queryKey: ["tags", tagId, "public"],
    queryFn: async () => {
      try {
        const response = await fetchTagPublic(tagId!);

        if (!response.ok) {
          throw new NetworkRequestError(await response.json(), response.status);
        }

        const tag: TTag = await response.json();

        const { metadata: nftMetadata } = await getNFTMetadata(tag);

        // claimed: false here helps us in testing.
        dispatch({ claimed: false });

        return { tag, nftMetadata };
      } catch (e) {
        if (e instanceof NetworkRequestError) {
          // setSearchParams('uid', tagId);
        }
        Bugsnag.notify(e as NotifiableError);
        throw e;
      }
    },
    enabled: !!tagId,
  });
};

export interface TagVerification {
  created_at: number;
  verify_type: TagVerificationType;
  city?: string;
  region?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

export const useTagVerificationsQuery = () => {
  const { uuid: tagId } = useTagParams();

  return useQuery({
    queryKey: ["tags", tagId, "verifications"],
    queryFn: async () => {
      try {
        const response = await fetchTagVerifications(tagId!);

        if (!response.ok) {
          throw new NetworkRequestError(await response.json(), response.status);
        }

        const responseBody: { verifications: TagVerification[] } =
          await response.json();

        return responseBody;
      } catch (e) {
        Bugsnag.notify(e as NotifiableError);
        throw e;
      }
    },
    enabled: !!tagId,
  });
};

export interface ITransfer {
  event_type: "transfer" | "mint" | "burn" | "sale";
  from_address: "string";
  to_address: "string";
  timestamp: "string";
}

export interface ITransferHistory {
  transfers: Array<ITransfer>;
}

// FIXME: This query's dependence on `tagId` is purely superficial, so we can
// remove the `useTagParams` and rename the query accordingly.

export const useTagHistoryQuery = () => {
  const { uuid: tagId } = useTagParams();
  const { tag } = useTagContext();

  return useQuery({
    queryKey: ["tags", tagId, "history"],
    queryFn: async () => {
      try {
        const response = await fetchTagHistory({
          chainId: tag.nft_chain_id!,
          contractAddress: tag.nft_contract_address!,
          tokenId: tag.nft_token_id!,
        });

        if (!response.ok) {
          throw new Error("Transfer history cannot be fetched.");
        }

        const responseBody: ITransferHistory = await response.json();
        return responseBody;
      } catch (e) {
        console.error(e);
      }
    },
    enabled: !!tagId,
  });
};
