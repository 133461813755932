import React from "react";

const useLoadingDelay = () => {
  const [animating, setAnimating] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimating(false);
    }, 2000);

    // 1200ms is the animation duration
    // + 400ms for displaying full logo and allowing some layout and render shifts underneath
    // + 200ms for fadeout

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return animating;
};

export default useLoadingDelay;
