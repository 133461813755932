import { lazy, Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useTagVerificationQuery,
  useTagVerificationsQuery,
} from "data/queries";
import useTagViewGAEvent from "hooks/useTagViewGAEvent";
import LoadingOverlay from "components/LoadingOverlay";

const tagDynamicImport = () => import("components/TagContent");
const TagContent = lazy(tagDynamicImport);

const VerificationPage = () => {
  const [searchParams] = useSearchParams();
  const { data, isLoading: isTagLoading } =
    useTagVerificationQuery(searchParams);
  const { isLoading: isTagVerificationsLoading } = useTagVerificationsQuery(); // hack to prefetch in parallel and render under modal
  const [isComponentLoading, setIsComponentLoading] = useState(true);

  useTagViewGAEvent(data?.tag);

  useEffect(() => {
    // manually trigger dynamic import in order to update state when component is loaded
    tagDynamicImport().then(() => {
      setIsComponentLoading(false);
    });
  }, []);

  return (
    <>
      <Suspense>
        <TagContent data={data} />
      </Suspense>
      <LoadingOverlay
        open={isComponentLoading || isTagLoading || isTagVerificationsLoading}
      />
    </>
  );
};

export default VerificationPage;
