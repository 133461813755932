import { useParams } from "react-router-dom";
import BuilderContent from "components/builder/BuilderContent";
import { Box, Typography } from "@mui/material";
import Header from "components/Header";
import Footer from "components/Footer";
import NftImage from "components/NftImage";

const BuilderPage = () => {
  const { builderPath, builderAPIKey } = useParams();

  const fullBuilderPath = `/b/${builderAPIKey}/${builderPath}`;

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Header />
        <Box>
          <Typography textTransform="uppercase" variant="h5" mt={1} mb={2}>
            Your Item Name Here
          </Typography>
          <NftImage src="https://ipfs.legitimate.tech/ipfs/Qmc5z2b9MmyhJBYMk7nj9tTrAZdNHpct1UnMuaRZahg6JA" />
          <Typography variant="body2">
            Your item description will appear here. The item name, image, and
            description here are preview placeholders that will be replaced with
            item information configured in the Legitimate Dashboard.
          </Typography>
        </Box>
        <BuilderContent
          builderPath={fullBuilderPath}
          builderAPIKey={builderAPIKey}
        />
      </Box>
      <Footer />
    </>
  );
};

export default BuilderPage;
